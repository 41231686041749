html {
  font-size: 20px;
}

/*.jumbotron > .lead > span {
  margin-left: 8px;
  padding-left: 8px;
}*/

.jumbotron > .lead > span::before {
  content: ' in ';
}

/* Helper classes */
.no-click {
  cursor: default;
}

/* Cards */
.card {
  cursor: pointer;
}

/* Carousel container */
.carousel-container {
  display: none;
  cursor: none;
}

.carousel-container.visible {
  display: block;
}

.carousel-container.active {
  cursor: auto;
}

/* Carousel controls */
.carousel-indicators li,
.carousel-control-prev,
.carousel-control-next {
  visibility: hidden;
  cursor: none;
}

.carousel-control-prev.disabled,
.carousel-control-next.disabled {
  visibility: hidden !important;
}

.active .carousel-indicators li,
.active .carousel-control-prev,
.active .carousel-control-next {
  visibility: visible;
  cursor: pointer;
}

.carousel-control-prev,
.carousel-control-next {
  top: 4px !important;
  bottom: 4px !important;
}

/* Captions */
.caption.d-none {
  display: block !important;
}

.autohideCaptions .caption.d-none {
  display: none !important;
}

.active.autohideCaptions .caption.d-none {
  display: block !important;
}

.carousel-caption h3 {
  font-size: 2em;
  text-shadow: 4px 4px 12px #333;
}

.carousel-caption p {
  font-size: 1.2em;
  text-shadow: 2px 2px 6px #333;
}

/* Slide images */
.carousel .img-fluid {
  width: 100%;
}

/* New fade transition */
.carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
  transition: opacity 1s ease-in-out;
}

.carousel-inner .carousel-item:first-of-type {
  position: relative;
}

.carousel-inner .active {
  opacity: 1;
}

/* Fade transition
.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity .75s ease-in-out;
}
.carousel-fade .carousel-item.active {
  opacity: 1;
  position: relative;
} */

/* Fullscreen */
.carousel-container.fullscreen {
  background: #000 !important;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}

.carousel-container.fullscreen > .carousel {
  top: 50%;
  transform: translateY(-50%);
}

/* Fullscreen exit button */
.exit-fullscreen {
  cursor: none;
  color: #fff;
  font-size: 20px;
  opacity: 0.5;
  position: absolute;
  right: calc(7.5% - 8.75px);
  bottom: 3.556%;
  z-index: 20;
  filter: drop-shadow(0px 0px 1px #777);
  visibility: hidden;
}

.active .exit-fullscreen {
  visibility: visible;
  cursor: pointer;
}

.exit-fullscreen:hover {
  opacity: 1;
}

h1 img {
  max-width:60.67px;
  margin-right:10px;
}
